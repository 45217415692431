<!--
--------------------------------------------------------------------------------
<copyright file="OpenSalesFunnel.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget.open_sales_funnel') }}</summary>
      <p>{{ $t('widget_info_text.open_sales_funnel_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="tip-bkg">{{ $t('widget_info_text.bars_tip_txt_cat') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.tooltip') }}</summary>
      <p>{{ $t('widget_info_text.open_sales_funnel_tooltip_txt') }}</p>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
